@import 'assets/styles/fonts';
@import 'assets/styles/colors';

.pageLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark-color);

  .title {
    @include setFont('bold', 16);
  }
}
