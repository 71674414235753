@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/styles/colors';

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px !important;
  border-radius: 5px;
  color: var(--black);

  &:hover {
    text-decoration: none;
    color: var(--black);
    background-color: var(--secondary-color);
  }

  .icon {
    transform: scaleX(-1);
  }
}

.active {
  background-color: var(--grey-1);
  color: var(--black);
  span {
    @include setFont('semiBold', 15);
  }

  &:hover {
    background-color: var(--grey-1);
  }
}

body[dir='rtl'] {
  .link {
    .icon {
      transform: scaleX(1) !important;
    }
  }
}
