@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url('../../assets/images/homeBackground.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.greyBackground {
    background-color: var(--grey-1);
    height: 100%;
    overflow: auto;
  }

  .content {
    flex: 1;
    padding: 50px 100px 40px 100px;

    @media (width < $sm) {
      padding: 20px;
    }

    @media (max-width: $md) {
      background-size: cover;
    }
  }

  .header {
    padding: 10px 80px;
    height: 80px;
    background-color: var(--color-white);
    box-shadow: 0px 5px 5px rgba(154, 158, 166, 0.5);

    position: sticky;
    z-index: 2;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (width < $sm) {
      padding: 10px 30px;
    }

    .logo {
      cursor: pointer;
    }

    .profile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: max-content;

      .icon {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin-inline-start: 10px;
      }
      .iconFav {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }

      .profileIcon {
        cursor: pointer;
      }

      .verticalSeperator {
        border-inline-start: 2px solid var(--form-input-border);
        margin-inline-start: 10px;
        margin-inline-end: 10px;
      }

      .name {
        width: max-content;
        margin-inline-end: 10px;

        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }
}

.outletWrapper {
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 15px !important;
  height: max-content;
  min-height: 200px;
  margin-bottom: 70px;
  box-shadow: 0px 0px 20px 0px rgba(137, 138, 141, 0.1);
}

body[dir='rtl'] {
  .header {
    flex-direction: row-reverse;
    .profile {
      flex-direction: row-reverse;
    }
  }
}
.aboutus {
  @include setFont('regular', 16);
  cursor: pointer;
}
