@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';

.subscribeModalContent {
  padding: 0px !important;
  border-radius: 25px !important;
  height: 500px;
  border: 5px solid var(--color-white) !important;

  @media (width < 992px) {
    height: max-content;
  }

  .wrapper {
    height: 100%;
    background-image: url('../../../assets/images/subscribeModalBackground.svg');
    background-repeat: no-repeat;
    background-size: 100% 200%;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (width < 992px) {
      padding-bottom: 20px;
    }

    .closeButton {
      cursor: pointer;
      margin-inline-start: auto;
      margin-inline-end: 30px;
      margin-top: 20px;
    }

    .image {
      width: 150px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      height: 190px;
      margin-top: 50px;
      background-color: var(--color-white);
      border-radius: 10px;
      padding: 20px;

      @media (width < 992px) {
        height: max-content;
      }

      .title {
        @include setFont('semiBold', 16);
      }

      .descriptionTitle {
        @include setFont('semiBold', 14);
        margin-inline-start: 5px;
      }

      .description {
        @include setFont('regular', 12);
        color: var(--light-grey);
      }
    }

    .download {
      height: 50px !important;
      padding: 0px 25px !important;
      border-radius: 15px !important;

      margin-top: 25px;
      width: 80%;
      background-color: var(--blue) !important;
      border: none !important;
      color: var(--color-white) !important;
      @include setFont('semiBold', 15);

      &:hover {
        color: var(--color-white);
      }
    }
  }
}
