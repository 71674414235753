$lineLightGrey: #ebedf3 !default;
$boxShadow: 0 0 30px 0 rgba(82, 63, 105, 0.03);
$lineSeperatorLightGrey: #ebedf3 !default;
$tintGreen: #16c5bd;

:root {
  --form-input-border: #ced4da;
  --secondary-color: #e4e6ef;
  --primary-color: #0e5aa7;
  --navy-blue: #333399;
  --dark-color: #1e1d2d;
  --light-grey: #757575;
  --dark-grey: #363d4e;
  --light-grey-2: #666666;
  --informative-text: #9ea2b9;
  --submit: #00ac74;
  --light-red: #fe3535;
  --danger: #c63a38;
  --black: #000000;
  --color-white: #ffffff;
  --grey-1: #f2f2f2;
  --facebook-blue: #47598f;
  --background-blue: #2e3192;
  --background-purple: #92278f;
  --grey-border: #c3c5ca;
  --background-beige: #d1ccbd;
  --pink: #a83252;
  --blue: #293578;
}
