@import '~bootstrap/scss/bootstrap';
@import 'assets/styles/bootstrap-override.scss';
@import 'assets/styles/colors';
@import 'assets/styles/variables';

body,
html {
  margin: 0;
  width: 100% !important;
  height: 100% !important;
}

#root {
  width: 100%;
  height: 100%;
}
