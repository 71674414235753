@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include setFont('semiBold', 30);
}
.backArrow {
  cursor: pointer;
}

.linksWrapper {
  height: max-content;
  border-radius: 25px;
  margin-inline-end: 10px;
  padding: 15px !important;
  box-shadow: 0px 10px 30px 0px rgba(137, 138, 141, 0.4);
  background-color: var(--color-white);

  @media (width<$md) {
    margin-inline-end: 0px;
    margin-bottom: 15px;
  }

  .logout {
    color: var(--danger);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include setFont('medium', 15);
    padding-inline-start: 5px !important;
    margin-top: 5px;
    &:hover {
      color: var(--danger);
    }
  }
}

.subscriptionBanner {
  margin-top: 15px;
  margin-inline-end: 15px;
  height: 200px;
  border-radius: 25px;
  background-color: var(--color-white);
  box-shadow: 0px 10px 30px 0px rgba(137, 138, 141, 0.4);

  position: relative;

  display: flex;
  flex-direction: row;

  .subscriptionTitle {
    @include setFont('semiBold', 14);
  }

  .subscriptionSubtitle {
    @include setFont('regular', 12);
  }

  .subscribe {
    height: 40px !important;
    padding: 0px 25px !important;
    border-radius: 15px !important;
    background-color: var(--blue) !important;
    border: none !important;
    color: var(--color-white) !important;
    @include setFont('semiBold', 15);

    &:hover {
      color: var(--color-white);
    }

    @media ($md <width <$xl) {
      width: 90px;
    }
  }

  @media (width<$md) {
    margin-inline-end: 0px;
    margin-bottom: 15px;
  }

  @media ($md<width<$xl) {
    height: min-content;
  }

  .logoWrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-image: url('../../assets/images/subscribeBackground.svg');
    opacity: 0.8;

    border-radius: 25px;
    background-size: 100% 100%;
  }
}

.outletWrapper {
  background-color: var(--color-white);
  border-radius: 25px;
  padding: 15px !important;
  height: max-content;
  min-height: 200px;
  box-shadow: 0px 10px 30px 0px rgba(137, 138, 141, 0.4);
}

.footer {
  width: 100%;
  justify-content: space-between;

  margin-top: 10px;

  @media (width < $md) {
    margin-top: 20px;
  }

  .copyright {
    @include setFont('regular', 14);
    color: var(--light-grey);
  }

  .termsAndPolicy {
    align-items: center;

    .termsPolicy {
      cursor: pointer;
      @include setFont('regular', 14);
      color: var(--dark-color);
      padding-inline-end: 10px;
      border-inline-end: 1px solid var(--light-grey);
    }

    .privacyPolicy {
      cursor: pointer;
      padding-inline-start: 10px;
      @include setFont('regular', 14);
      color: var(--dark-color);
    }
  }
}

.deleteButtonWrappers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100% !important;
  gap: 15px;

  .cancel {
    height: 50px;
    padding: 0px 25px;
    width: 100%;
    border-radius: 15px;
    background-color: var(--blue);
    border: none;
    color: var(--color-white);
    @include setFont('semiBold', 15);
    &:hover {
      color: var(--color-white);
    }
  }

  .delete {
    width: 100%;
    height: 50px;
    padding: 0px 25px;
    background-color: none;
    border-radius: 15px;
    color: var(--danger);
    border: 1px solid var(--danger);
    @include setFont('semiBold', 15);
    &:hover {
      color: var(--danger);
    }
  }
}

body[dir='rtl'] {
  .subscriptionBanner {
    .subscriptionSubtitle {
      @media (width < $xl) {
        @include setFont('regular', 10);
      }
    }
    .logoWrapper {
      transform: scaleX(-1);
    }
  }
}
