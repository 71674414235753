@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include setFont('semiBold', 30);
}

.copyright {
  @include setFont('regular', 14);
  color: var(--light-grey);
}

.termsPolicy {
  cursor: pointer;
  @include setFont('regular', 14);
  color: var(--dark-color);
  padding-inline-end: 10px;
  border-right: 1px solid var(--light-grey);
}

.privacyPolicy {
  cursor: pointer;
  padding-inline-start: 10px;
  @include setFont('regular', 14);
  color: var(--dark-color);
}
.backArrow {
  cursor: pointer;
}
