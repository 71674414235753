body,
html {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100%;
  font-family: 'Almarai-Regular', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: 'Almarai-Regular', 'sans-serif';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Almarai-Bold';
  src: url('./assets/fonts/Almarai-Bold.ttf');
}

@font-face {
  font-family: 'Almarai-ExtraBold';
  src: url('./assets/fonts/Almarai-ExtraBold.ttf');
}

@font-face {
  font-family: 'Almarai-Light';
  src: url('./assets/fonts/Almarai-Light.ttf');
}

@font-face {
  font-family: 'Almarai-Regular';
  src: url('./assets/fonts/Almarai-Regular.ttf');
}

* {
  outline: none;
  font-family: 'Almarai-Regular', sans-serif;
}

body[dir='rtl'] {
  text-align: right;
  div.no-rotate,
  svg:not(.no-rotate),
  img:not(.no-rotate) {
    transform: scaleX(-1);
  }
}
