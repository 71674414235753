@import 'assets/styles/variables';

.nonAuthContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/images/background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  .nonAuthBackground {
    width: 100%;
    height: 100vh;
  }
  .illustrationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 40px;
    margin-bottom: 10px;

    .logo {
      width: 200px;
    }
    @media (max-width: $sm) {
      .logo {
        display: none;
      }
      margin: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 55px;
  padding-block: 0.5rem;
  position: relative;
  margin-bottom: auto;
  width: 70%;
  @media (width <$sm) {
    background-color: transparent;
    width: auto;
  }
  .languageSwitcher {
    position: absolute;
    top: 20px;
    right: 40px;
    align-items: flex-end;

    .languageButton {
      width: auto;
    }
  }
}

body[dir='rtl'] {
  .nonAuthContainer {
    flex-direction: column !important;
    justify-content: space-between;
  }
  .content {
    flex-direction: column;
  }
  .languageSwitcher {
    left: 40px;
    right: auto;
  }
}
