@import 'assets/styles/variables';

.modal-fullscreen-md-down {
  @media (width< $sm) {
    height: 90vh;
    position: fixed;
    bottom: 0;
  }
}

.custom-modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
