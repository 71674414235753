@import 'assets/styles/variables';

.svg-button {
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: initial;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg,
  .spinner-border {
    margin-inline-end: 10px;
  }
  &.no-margin {
    svg {
      margin: 0;
    }
  }

  .title {
    font-family: 'Almarai-Bold', sans-serif;
    text-align: start;
    @media (max-width: $md) {
      text-align: center;
    }
  }
  .subtitle {
    color: var(--informative-text);
  }

  &.active {
    .title {
      text-align: start;
      color: var(--tint-green);
    }
    svg {
      fill: var(--tint-green);
    }
    .subtitle {
      color: var(--tint-green);
    }
  }
}

.btn-danger-inverted {
  color: var(--light-red) !important;
  border: none !important;
  background-color: transparent !important;
  // font-size: inherit !important;
}
